import {PropsWithChildren} from 'react';
import {SVGSpec} from '../Admin/Form/Picture/SVGPlacer';
import {coverHeight} from '../../lib/style';
import {Box} from '@mui/material';

type Props = PropsWithChildren<{
    svgSpec?: SVGSpec | undefined;
}>;

export default function LogoWrapper({children, svgSpec}: Props) {
    return (
        <Box
            sx={{
                backgroundColor: svgSpec?.bgColor,
                img: {
                    maxHeight: coverHeight,
                },
                ...(svgSpec?.scale
                    ? {
                          [import.meta.env.SSR
                              ? '& > div'
                              : '& > div:has(.loaded)']: {
                              transform: `scale(${Math.round(svgSpec.scale) / 100})`,
                          },
                      }
                    : {}),
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                {children}
            </div>
        </Box>
    );
}
